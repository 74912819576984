import { required, minLength } from 'vuelidate/lib/validators';

export default {
  validations: {
    verificationCode: {
      required,
      minLength: minLength(6),
    },
  },
  computed: {
    isVerificationCodeError() {
      return (
        (this.$v.verificationCode.$dirty &&
          this.$v.verificationCode.$invalid) ||
        !!this.getErrors.confirmation_code ||
        !!this.getErrors.code ||
        !!this.getErrors.otp
      );
    },
    verificationCodeErrorText() {
      if (
        !this.$v.verificationCode.required ||
        !this.$v.verificationCode.minLength
      ) {
        return 'The confirmation code must contains 6 digits';
      }
      if (!!this.getErrors.confirmation_code) {
        return this.getErrors.confirmation_code[0];
      }
      if (!!this.getErrors.code) {
        return this.getErrors.code[0];
      }
      if (!!this.getErrors.otp) {
        return this.getErrors.otp[0];
      }
    },
  },
};
